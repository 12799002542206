<template>
    <dashboard-layout>
        <v-btn small text color="primary" :to="{name: 'Home'}">Terug naar collectieoverzicht</v-btn>
        <v-card flat>
            <v-card-title>Bekijk collectie: {{ collectionName }}</v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="items"
                    :items-per-page="-1"
                    dense
                    disable-pagination
                    disable-sort
                    disable-filtering
                    hide-default-footer
                    show-select
                    v-model="selectedRows"
                    selectable-key="id"
                    :loading="loading">
                    <template v-slot:top>
                        <v-row class="pb-3">
                            <v-col class="shrink" style="min-width: 220px;">
                                <v-menu>
                                    <template v-slot:activator="{on, attrs}">
                                        <v-btn v-on="on" v-bind="attrs" small block>
                                            Bulk acties <v-icon right>mdi mdi-chevron-down</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            v-for="(item, index) in bulkActions"
                                            :key="index"
                                            link
                                            @click="() => { bulkAction = item.value; doBulkAction(); }"
                                        >
                                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>

                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col class="shrink" style="min-width: 150px;">
                                <v-btn color="primary" small block @click="doBulkExport(true)"><v-icon left>mdi mdi-download</v-icon> Exporteer</v-btn>
                            </v-col>
                            <v-col class="shrink" style="min-width: 150px;">
                                <v-btn color="success" small block @click="showForm = true"><v-icon left>mdi mdi-plus</v-icon> Nieuw</v-btn>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:item.media_url="{item}">
                        <v-text-field dense
                                      label="Publieke link"
                                      persistent-placeholder readonly single-line hide-details
                                      @focus="$event.target.select()"
                                      v-model="item.media_url"
                        >
                            <template v-slot:append-outer>
                                <copy-button :content="item.media_url"></copy-button>
                            </template>
                        </v-text-field>
                    </template>
                    <template v-slot:item.created_at="{item}">
                        <span>{{ new Date(item.created_at).toLocaleString() }}</span>
                    </template>
                    <template v-slot:item.actions="{item}">
                        <v-btn icon small @click="deleteId = item.id"><v-icon small>mdi mdi-delete</v-icon></v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-dialog :value="uploadFiles.length > 0" @input="uploadFiles = []" max-width="640px" :persistent="!uploaderDone">
            <v-card>
                <v-card-title>Upload</v-card-title>
                <v-card-subtitle>Sluit de pagina niet af voordat de uploads klaar zijn</v-card-subtitle>
                <v-list dense max-height="600px" style="overflow-y: scroll">
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-title>Geupload {{ uploaderProgress }} / {{ uploaderTotal }}</v-list-item-title>
                            <v-progress-linear :value="Math.round((uploaderProgress * 100) / uploaderTotal)"></v-progress-linear>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line v-for="(uploadFile, idx) in uploadFiles" :key="idx">
                        <v-list-item-content>
                            <v-list-item-title>{{ uploadFile.file.name }}</v-list-item-title>
                            <v-list-item-subtitle>{{ uploadFile.progress }}% — {{ uploadFile.state }}</v-list-item-subtitle>
                            <v-progress-linear :value="uploadFile.valid ? uploadFile.progress : 100" :color="uploadFile.error || !uploadFile.valid ? 'error' : uploadFile.success ? 'success' : 'blue'"></v-progress-linear>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn icon>
                                <v-icon color="error" v-if="!uploadFile.success" @click="removeUpload(uploadFile)">mdi-delete</v-icon>
                                <v-icon color="success" v-else @click="removeUpload(uploadFile)">mdi-check-bold</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="uploadFiles = []" :disabled="!uploaderDone" text>Sluiten</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showForm" max-width="640px">
            <v-card>
                <v-card-title>Nieuw product</v-card-title>
                <v-card-text>
                    <v-text-field v-model="productName" label="Naam product (laat leeg voor automatisch)"></v-text-field>
                    <v-file-input v-model="attachment" label="Kies foto..." accept="image/*" :rules="[v => !!v || 'Veld is verplicht']"></v-file-input>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="secondary" @click="showForm = false">Sluiten</v-btn>
                    <v-btn type="submit" text color="primary" @click="saveNewProduct">Opslaan</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog max-width="640px" :value="deleteId != null">
            <v-card>
                <v-card-title>Product verwijderen</v-card-title>
                <v-card-text>
                    <p>
                        Weet u zeker dat u dit product wilt verwijderen? De links zullen niet meer beschikbaar zijn!
                    </p>
                    <span class="red--text font-weight-bold">Deze actie kan NIET ongedaan worden gemaakt.</span>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="secondary" @click="deleteId = null">Sluiten</v-btn>
                    <v-btn text color="error" @click="deleteItem">Verwijderen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog max-width="640px" :value="bulkDeleteDialog">
            <v-card>
                <v-card-title>Producten verwijderen</v-card-title>
                <v-card-text>
                    <p>
                        Weet u zeker dat u deze producten wilt verwijderen? De links zullen niet meer beschikbaar zijn!
                    </p>
                    <span class="red--text font-weight-bold">Deze actie kan NIET ongedaan worden gemaakt.</span>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="secondary" @click="bulkDeleteDialog = false">Sluiten</v-btn>
                    <v-btn text color="error" @click="doBulkDelete">Verwijderen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </dashboard-layout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import CopyButton from "../components/CopyButton";
import {http} from "../http";
const defaultFile = {
    progress: 0,
    state: "Waiting...",
    completed: false,
    error: false,
    valid: true
};
export default {
    name: "Collectie",
    components: {DashboardLayout, CopyButton},
    data() {
        return {
            collectionName: '',
            uploadFiles: [],
            uploaderDone: true,
            uploaderProgress: 0,
            uploaderTotal: 0,
            headers: [
                {text: "Productcode", value: 'naam'},
                {text: "Aangemaakt op", value: 'created_at'},
                {text: "Link", value: 'media_url'},
                { text: 'Acties', value: 'actions', sortable: false }
            ],
            items: [
            ],
            loading: false,
            bulkActions: [
                {text: "Exporteren", value: "export"},
                {text: "Verwijderen", value: "delete"},
            ],
            bulkAction: null,
            selectedRows: [],
            bulkDeleteDialog: false,
            deleteId: null,
            showForm: false,
            productName: "",
            attachment: null
        }
    },
    methods: {
        async loadData() {
            this.loading = true;
            let resp = await http.get(`collectie/${this.$route.params.id}/product`);
            this.items = resp.data.data;
            this.collectionName = resp.data.meta.collection_name;
            this.loading = false;
        },
        async deleteItem() {
            await http.post(`collectie/${this.$route.params.id}/product/${this.deleteId}`, {_method: 'DELETE'});
            this.deleteId = null;
            await this.loadData();
        },
        doBulkAction() {
            if (this.bulkAction === "delete")
                this.bulkDeleteDialog = true;
            else if (this.bulkAction === "export") {
                this.doBulkExport();
            }
            this.bulkAction = "";
        },
        async saveNewProduct() {
            const fd = new FormData();
            fd.append('product_name', this.productName);
            fd.append('attachment', this.attachment);
            try {
                await http.post(`collectie/${this.$route.params.id}/product`, fd);
                this.attachment = null;
                this.collectionName = "";
                this.productName = "";
                this.showForm = false;
                this.$refs.form.resetValidation();
            } catch(e) {
//
            } finally {
                await this.loadData();
            }
        },
        async doBulkDelete() {
            try {
                await http.post(`collectie/${this.$route.params.id}/product/bulk`, {
                    items: this.selectedRows.map(({id}) => id),
                    _method: 'DELETE'
                });
            } catch (e) {
                //
            } finally {
                this.bulkDeleteDialog = false;
                await this.loadData();
            }
        },
        async doBulkExport(all = false) {
            let data = {
                items: this.selectedRows.map(({id}) => id),
            };
            if (all)
                data = {};
            const response = await http.post(`collectie/${this.$route.params.id}/product/bulk`, data, {
                responseType: "blob"
            });
            const fileName = response.headers['content-disposition'].replace("attachment; filename=", "").replace(/"/g, "");
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
        },
        async onDrop(ev) {
            ev.preventDefault();
            for(let file of ev.dataTransfer.files) {
                let f = {...defaultFile, file: file, valid: file.type.startsWith("image/")};
                if (!f.valid)
                    f.state = "Ongeldig bestandstype";
                this.uploadFiles.push(f);
            }
            await this.processUploadFiles();

        },
        onDragOver(ev) {
            ev.preventDefault();
        },
        removeUpload(file) {
            this.uploadFiles = this.uploadFiles.filter(x => x !== file);
            file.skip = true;
            if (!file.success)
                this.uploaderTotal--;
        },
        onWindowClose(e) {
            if (!this.uploaderDone) {
                e.preventDefault();
                e.returnValue = '';
                return;
            }
            delete e['returnValue'];
        },
        async processUploadFiles() {
            this.uploaderDone = false;
            this.uploaderProgress = 0;
            this.uploaderTotal = this.uploadFiles.length;
            for (let file of this.uploadFiles) {
                if (file.skip)
                    continue;
                try {
                    file.state = "Uploading...";
                    let fd = new FormData();
                    fd.append('attachment', file.file);
                    await http.post(`/collectie/${this.$route.params.id}/product`, fd, {
                        onUploadProgress: (e) => {
                            file.progress = Math.round((e.loaded * 100) / e.total);
                        }
                    })
                    file.state = "Uploaded";
                    file.success = true;
                } catch(e) {
                    if (e.response) {
                        if (e.response.status === 422) {
                            file.valid = false;
                            file.state = "Ongeldig bestandstype";
                        } else {
                            file.error = true;
                            file.state = "Error";
                        }
                    } else {
                        file.error = true;
                        file.state = "Error";
                    }
                }
                this.uploaderProgress++;
            }
            this.uploaderDone = true;
            await this.loadData();
        },
    },
    async mounted() {
        window.addEventListener('beforeunload', this.onWindowClose);
        window.addEventListener('dragover', this.onDragOver);
        window.addEventListener('drop', this.onDrop);
        await this.loadData();
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', this.onWindowClose);
        window.removeEventListener('dragover', this.onDragOver);
        window.removeEventListener('drop', this.onDrop);
    }
}
</script>

<style scoped>
    .uploadhandler:before {
        content: "";
    }
    .uploadhandler {
    }
</style>
