<template>
    <dashboard-layout>
        <v-card flat>
            <v-card-title>Collecties</v-card-title>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="items"
                    :items-per-page="-1"
                    dense
                    disable-pagination
                    disable-sort
                    disable-filtering
                    hide-default-footer
                    show-select
                    v-model="selectedRows"
                    @click:row="$router.push({name: 'ShowCollectie', params: {id: $event.id}})"
                    :loading="loading">
                    <template v-slot:top>
                        <v-row class="pb-3">
                            <v-col class="shrink" style="min-width: 300px;">
                                <v-menu>
                                    <template v-slot:activator="{on, attrs}">
                                        <v-btn v-on="on" v-bind="attrs" small block>
                                            Bulk acties <v-icon right>mdi mdi-chevron-down</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            v-for="(item, index) in bulkActions"
                                            :key="index"
                                            link
                                            @click="() => { bulkAction = item.value; doBulkAction(); }"
                                        >
                                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col class="shrink" style="min-width: 150px;">
                                <v-btn color="success" block @click="showForm = true"><v-icon left>mdi mdi-plus</v-icon> Nieuw</v-btn>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:item.actions="{item}">
                        <v-btn icon small @click.prevent="() => {$event.stopPropagation(); loadEdit(item.id) }"><v-icon small>mdi mdi-pencil</v-icon></v-btn>
                        <v-btn icon small @click.prevent="() => {$event.stopPropagation(); deleteId = item.id }"><v-icon small>mdi mdi-delete</v-icon></v-btn>
                    </template>
                    <template v-slot:item.created_at="{item}">
                        <span>{{ new Date(item.created_at).toLocaleString() }}</span>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-dialog v-model="showForm" @input="() => { formData.id = null; formData.naam = ''; }" max-width="320px">
                <v-card>
                    <v-card-title>{{ formTitle }}</v-card-title>
                    <v-card-text>
                        <v-text-field label="Naam" v-model="formData.naam" single-line></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text color="secondary" @click="closeFormDialog">Sluiten</v-btn>
                        <v-btn text color="primary" @click="save">Opslaan</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog max-width="640px" :value="deleteId != null">
                <v-card>
                    <v-card-title>Collectie verwijderen</v-card-title>
                    <v-card-text>
                        <p>
                            Weet u zeker dat u deze collectie wilt verwijderen? alle producten worden ook verwijderen en de links zullen niet meer beschikbaar zijn!
                        </p>
                         <span class="red--text font-weight-bold">Deze actie kan NIET ongedaan worden gemaakt.</span>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text color="secondary" @click="deleteId = null">Sluiten</v-btn>
                        <v-btn text color="error" @click="deleteItem">Verwijderen</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog max-width="640px" :value="bulkDeleteDialog">
                <v-card>
                    <v-card-title>Collecties verwijderen</v-card-title>
                    <v-card-text>
                        <p>
                            Weet u zeker dat u deze collecties wilt verwijderen? alle producten worden ook verwijderen en de links zullen niet meer beschikbaar zijn!
                        </p>
                        <span class="red--text font-weight-bold">Deze actie kan NIET ongedaan worden gemaakt.</span>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text color="secondary" @click="bulkDeleteDialog = false">Sluiten</v-btn>
                        <v-btn text color="error" @click="doBulkDelete">Verwijderen</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </dashboard-layout>
</template>

<script>
import DashboardLayout from "../layouts/DashboardLayout";
import {http} from "../http";
export default {
    name: "Home",
    components: {DashboardLayout},
    data() {
        return {
            headers: [
                {text: "Collectie naam", value: 'naam'},
                {text: "Producten", value: 'product_count'},
                {text: "Aangemaakt op", value: 'created_at'},
                { text: 'Acties', value: 'actions', sortable: false }
            ],
            items: [
            ],
            loading: false,
            selectedRows: [],
            bulkActions: [
                {text: "Exporteren", value: "export"},
                {text: "Verwijderen", value: "delete"},
            ],
            bulkAction: null,
            bulkDeleteDialog: false,
            showForm: false,
            formData: {
                id: null,
                naam: ""
            },
            deleteId: null
        }
    },
    methods: {
        async loadData() {
            this.loading = true;
            let resp = await http.get("collectie");
            this.items = resp.data.data;
            this.loading = false;
        },
        closeFormDialog() {
            this.formData.id = null;
            this.formData.naam = "";
            this.showForm = false;
        },
        async save() {
            if (!this.formData.id)
                await http.post("collectie", {...this.formData});
            else
                await http.post(`collectie/${this.formData.id}`, {...this.formData, _method: 'PUT'});
            this.closeFormDialog();
            await this.loadData();
        },
        async loadEdit(id) {
            const resp = await http.get(`collectie/${id}`);
            this.formData = {...resp.data.data};
            this.showForm = true;
        },
        async deleteItem() {
            await http.post(`collectie/${this.deleteId}`, {_method: 'DELETE'});
            this.deleteId = null;
            await this.loadData();
        },
        doBulkAction() {
            if (this.bulkAction === "delete")
                this.bulkDeleteDialog = true;
            else if (this.bulkAction === "export") {
                this.doBulkExport();
            }
            this.bulkAction = "";
        },
        async doBulkDelete() {
            try {
                await http.post(`collectie/bulk`, {
                    items: this.selectedRows.map(({id}) => id),
                    _method: 'DELETE'
                });
            } catch (e) {
                //
            } finally {
                this.bulkDeleteDialog = false;
                await this.loadData();
            }
        },
        async doBulkExport(all = false) {
            let data = {
                items: this.selectedRows.map(({id}) => id),
            };
            if (all)
                data = {};
            const response = await http.post(`collectie/bulk`, data, {
                responseType: "blob"
            });
            const fileName = response.headers['content-disposition'].replace("attachment; filename=", "").replace(/"/g, "");
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
        },
    },
    computed: {
        formTitle() {
            return this.formData.id !== null ? "Bewerk collectie" : "Nieuwe collectie"
        }
    },
    async mounted() {
        await this.loadData();
    }
}
</script>

<style>
    .hide-footer .v-data-footer {
        display: none !important;
    }
</style>
