import {http} from "../../http";

export default {
    login ({ commit }, credentials) {
        return http
            .post('/login', credentials)
            .then(({ data }) => {
                commit('setUserData', data)
            })
    },

    logout ({ commit }) {
        commit('clearUserData')
    }
}
