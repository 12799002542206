import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from "../views/Login";
import Collectie from "../views/Collectie";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
      meta: {
        auth: true
      }
  },
    {
        path: '/collectie/:id',
        name: 'ShowCollectie',
        component: Collectie,
        meta: {
            auth: true
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            auth: false
        }
    },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem('user')

    if (to.matched.some(record => record.meta.auth) && !loggedIn) {
        next('/login')
        return
    }
    next()
})

export default router
