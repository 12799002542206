<template>
    <v-tooltip open-on-click :open-on-hover="false" bottom>
        <template v-slot:activator="{on}">
            <v-icon @click="copyText(on, $event)" @blur="on.blur" retain-focus-on-click>mdi mdi-content-copy</v-icon>
        </template>
        Gekopieerd naar klembord!
    </v-tooltip>
</template>

<script>
export default {
    name: "CopyButton",
    props: {
        content: {
            type: String,
            default: ""
        }
    },
    methods: {
        copyText(on, ev) {
            navigator.clipboard.writeText(this.content).then(function () {
                on.click(ev);
            }, function () {
                //
            });
        }
    }
}
</script>

<style scoped>

</style>
